#err {
  margin: 5px;
  color: red;
}

.hidden {
  display: none !important;
}

.preview {
  margin: 10px;
  display: inline-block;
}
